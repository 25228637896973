<template>
    <div>
        <div class="breadcrumb">
            <h1>Lista de Clientes</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-6">
                                <router-link :to="{ name: 'cliente-create' }">
                                    <button class="btn th-custom-color mr-2"
                                    >Nuevo
                                    </button>
                                </router-link>
                                <router-link :to="{ name: 'cliente-importar' }">
                                    <button class="btn th-custom-color mr-2"
                                    >Importar
                                    </button>
                                </router-link>
                            </div>
                            <div class="col-sm-6">
                                <div class="text-right">
                                    <label class="switch switch-success mr-3">
                                        <input type="checkbox"
                                            id="filterbyState" 
                                            :checked="estado" 
                                            v-model="estado" 
                                            @change="listarCliente">
                                        <span class="slider"></span>
                                        <span>{{ labelActivos }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-1">
                            <div class="col-sm-3">
                                <div class="btn-group">
                                    <span class="pt-2 mr-1">Mostrar </span>
                                    <b-form-select 
                                    v-model="porPagina" 
                                    :options="paginaOpciones">
                                    </b-form-select>
                                    <span class="pt-2 ml-1"> Registros</span>
                                </div>
                            </div>
                            <div class="col-sm-3 offset-sm-6 text-right">
                                <div class="form-group row">
                                    <label for="search" class="col-sm-5 col-form-label text-right">Buscar:</label>
                                    <div class="col-sm-7">
                                        <input type="text" id="search" class="form-control" v-model="filter"
                                         @keyup="filtrarBusqueda">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: block; width: 100%">
                            <b-table
                                class="table table-striped table-bordered table-hover"
                                :fields="fields"
                                :busy="isBusy"
                                :items="clientes"
                                :per-page="porPagina"
                                width="100%">
                                <template #cell(editar)="items" v-if="estado">
                                    <router-link
                                    :to="{ name: 'cliente-edit', params: {id: encrypt(items.item.id)}}"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Editar">
                                        <i class="nav-icon i-Pen-5 text-success font-weight-bold"
                                        title="editar" style="cursor: pointer;">
                                        </i>
                                    </router-link>
                                </template>
                                <template #cell(eliminar)="data" v-if="estado">
                                    <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                                    style="cursor: pointer" title="eliminar"
                                      @click="showDeleteConfirmation(data.item)"></i>
                                </template>
                                <div slot="table-busy" class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Cargando...</strong>
                                </div>
                            </b-table>
                            <div class="row">
                                <div class="col-sm-8"></div>
                                <div class="col-sm-4">
                                    <b-pagination
                                        v-model="paginaActual"
                                        :total-rows="totalFila"
                                        :per-page="porPagina"
                                        @input="listarCliente"
                                        class="my-0 float-right"

                                    ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TheModalDestroy/>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import TheModalDestroy from '../components/theModalDestroy.vue';

export default {
    name: 'index',
     components: {
        TheModalDestroy,
    },
    data() {
        return {
            delayTimeout: null,
        };
    },
    created() {
        this.listarCliente();
    },
    methods: {
        ...call("cliente/clienteIndex", ["listarCliente",
        ]),
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.listarCliente, DELAY);
        },
        encrypt(id) {
            return btoa(id);
        },
        showDeleteConfirmation(item) {
            this.modalDestroyConfirmation = true;
            this.registro = item;
        },
    },
    computed: {
         labelActivos() {
            if (this.estado) {
                return 'Activos';
            }
            return 'Inactivos';
        },
        ...get('cliente/clienteIndex', [
            "clientes",
            "fields",
            "isBusy"
        ]),
        ...sync('cliente/clienteIndex', [
            "filter",
            "estado",
            "porPagina",
            'paginaActual',
            'paginaOpciones',
            'totalFila'
        ]),
        ...sync('cliente/clienteDestroy', [
            'modalDestroyConfirmation',
            'registro',
        ]),
       
    },
    beforeDestroy() {
        this.$store.dispatch('cliente/clienteIndex/reset');
    },
    watch: {},
   
}
</script>

<style scoped>
.btn-primary {
    color: white;
    background-color: #00bd7e;
    border-color: #00bd7e;
}
</style>